.Footer {
    border-top: 1px solid;
    border-color: #F7E099;
}
footer {
    min-height: 130px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;  
    background-color: #FFF0C3;
    color: #2C4143;
}
footer header {
    margin: 0;
}
footer h1, h2 {
    font-weight: normal;
}
footer h5 {
    margin-bottom: 0;
}
footer .footerLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
footer ul {
    list-style-type: none;
}
footer ul li {
    display: inline-block;
}
footer a {
    padding: 8px;
    margin: 2px;
    background-color: #FFCA00;
    color: #293E33;
    text-decoration: none;
    border: 1px solid;
    border-color: #D7A913;
    border-radius: 4px;
    
}
footer a:hover {
    background-color: #CEA815;
    color: #293E33;
}