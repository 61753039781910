a.inline-link {
    display: inline;
}

div.source-results {
    margin-left: 1em;
}

h2.results-list {
    margin-left: -1em;
}

li.list-item {
    text-indent: -2em;
}

span.source-text {
    color: black;
    font-size: 0.75em;
    margin-left: 1em;
}