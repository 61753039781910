header.modal-header {
    background-color: #FEC514;
    padding: 0 10px;
}
header div.header-titles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 5px;
 }
header.modal-header h2 {
    font-size: 1em;
    margin-bottom: 5px;
    padding-right: 1em;
}
header.modal-header p.source {
    color: #cd5360;
    font-weight: normal;
    margin: auto 0 5px 0;
}
button.close {
    margin: 0 0 0 auto;
    cursor: pointer;
    font-size: 36px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    text-transform: none;
    border: 0;
    -webkit-appearance: none;
    background: 0 0;
    border: 0;
    opacity: .65;
}
main.modal-video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}
button.close:hover {
    opacity: 1;
}
div.inline-button {
    display: inline;
}
button.button-open-modal {
    display: inline-block;
    cursor: pointer;
    padding: .2rem .60rem;
    margin: 0 0 0 10px;
    color: #212529;
    background-color: #ffc107;
    border-color: #212529;
    border: 1px solid;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button.button-open-modal:focus {
    box-shadow: 0 0 0 .2rem rgba(222,170,12,.5);
}
button.button-open-modal:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}
button.button-close-modal-video {
    height: 2.2em;
    min-width: 80px;
    background: #3e79a0;
    color: #FFF;
    padding: 5px 15px;
    margin: 5px 0 8px 0;
    border:0 none;
    cursor:pointer;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid;
    border-color: #29506b;
    font-size: 1em;
}
button.button-close-modal-video:hover {
    background: #468dba;
    border-color: #2c5875;
}