main {
  flex: 1 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: left;
}
main a {
  color: #2a4494;
}
main a:hover {
  color: #577590;
}
main section h2 {
  color: #cd5360;
  /* color: #fa6367; */
  }
main li {
  text-align: left;
}
mark {
  background: #f2de8c;
}