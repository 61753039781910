.Header {
  background-color: #214660;
  box-shadow: 0px 1px 1px #888888;
}
header {
  flex: 1 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
}
header h1 {
  font-weight: normal;
  color: #F9FFFC;
  text-shadow: #FEC514 1px 0 1px;
  /*¤FC0*/
}