html, body {
  height: 100%;
  margin: 0;
}
div.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.Main {
  flex: 1 0 auto;
}
.Footer {
  flex-shrink: 0;
}