main .Search {
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
main .SearchBar {
  width: 80vw;
  max-width: 500px;
  margin: 10px;
  padding: 0 12px 0 12px;
  border-radius: 3px;
  box-shadow: 0px 3px 5px #888888;
}
.SearchBar .formInputs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 1em;
}
.SearchBar .inputText {
  min-width: 150px;
  height: 2.8em;
  margin-bottom: 1em;
  margin-right: 2px;
  font-size: 1.3em;
}
.SearchBar input[type="text"] {
  flex: 1;
}
.SearchBar input.submitSearch {
  margin-bottom: 1em;
}
.SearchBar .submitSearch {
  height: 2.8em;
  width: 80px;
  background: #3e79a0;
  color: #FFF;
  padding: 5px 15px; 
  border: 0 none;
  cursor: pointer;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #29506b;
  font-size: 1.3em;
}
.SearchBar .submitSearch:hover {
  background: #468dba;
  border-color: #2c5875;
}
main .SearchResults {
  width: 80vw;
  max-width: 500px;
}
.SearchResults a {
  font-size: 1.2em;
}
mark {
  background: #f2de8c;
}